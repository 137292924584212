<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-19 14:31:39
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-10 10:56:41
-->
<template>
  <div class="taskDetailPage clearfix">
    <div class="left_section">
      <div class="section">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/tasks' }">任务大厅</el-breadcrumb-item>
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <span class="sn">{{content.sn}}</span> -->
        <h2>
          <p class="money">
            {{content.taskMoney=="费用面议"?"":content.amount}}
            <span
              :class="{only:content.amount==undefined||content.amount.length == 0||content.taskMoney == '费用面议'}"
            >
              <template v-if="content.taskMoney == '费用面议'">费用面议</template>
              <template v-else-if="content.taskMoney == '固定价格'">/元</template>
              <template v-else-if="content.taskMoney == '按月'">元/月</template>
              <template v-else-if="content.taskMoney == '按周'">元/周</template>
              <template v-else-if="content.taskMoney == '按天'">元/天</template>
              <template v-else-if="content.taskMoney == '按时'">元/小时</template>
            </span>
          </p>
          <p class="title text-ellipsis">{{content.ecompanyJobCategoryName}}</p>
        </h2>

        <h3 class="text-ellipsis">{{content.taskName}}</h3>
        <p v-if="content.settlement">结算方式：{{content.settlement}}</p>
        <p>发布时间： {{content.publishDate}}</p>
        <p>
          截止时间：
          <template v-if="content.lastEndDate == undefined">长期有效</template>
          <template
            v-else-if="(timeLeftCount(content.lastApplyDate) <= 0) || (content.applyStatus == 'stop')"
          >已截止</template>
          <template v-else>{{content.lastEndDate}}</template>
        </p>

        <p>地 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; 区：{{content.cityName?content.cityName:"不限"}}</p>
        <p>发&nbsp;布&nbsp;方：{{content.ecompanyName}}</p>
        <div class="btn">
          <template v-if="content.isApply">
            <el-button type="warning" v-if="content.employeeTaskStatus == 'applying'">申请中</el-button>
            <el-button type="primary" v-else-if="content.employeeTaskStatus == 'doing'">进行中</el-button>
            <el-button
              :disabled="!canApply"
              @click="handleApplyTasks"
              type="success"
              v-else-if="content.employeeTaskStatus == 'finish'|| content.employeeTaskStatus == 'nopass' || content.employeeTaskStatus == 'cancelled'"
            >申请</el-button>
          </template>
          <template v-else>
            <el-button
              :disabled="!canApply"
              type="success"
              @click="handleApplyTasks"
              v-if="content.applyStatus == 'applying' && content.employeeTaskStatus != 'nopass'"
            >申请</el-button>
            <el-button type="info" v-if="content.employeeTaskStatus == 'nopass'">不可申请</el-button>
            <el-button
              type="info"
              v-if="content.applyStatus == 'stop' && content.employeeTaskStatus != 'nopass'"
            >已截止</el-button>
          </template>
        </div>
      </div>
      <div class="section">
        <header>任务详情</header>
        <div class="content" v-html="content.taskRemark"></div>
      </div>
    </div>
    <taskRight />
  </div>
</template>

<script>
import api from "@/api/list";
import taskRight from "./components/taskRight.vue";
export default {
  name: "taskDetail",
  components: {
    taskRight,
  },
  data() {
    return {
      canApply: true,
      content: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  watch: {
    $route: function () {
      this.getDetail();
    },
  },
  methods: {
    getDetail() {
      api.getTasksDetail({ taskId: this.$route.query.taskId }).then((res) => {
        if (res && res.data) {
          this.content = Object.assign({}, res.data);
        } else {
          this.content = {};
        }
      });
    },
    handleApplyTasks() {
      this.canApply = false;
      this.$store.commit("SET_ENTERROUTE", this.$route);
      api
        .applyTasks({ taskId: this.$route.query.taskId }, { method: "post" })
        .then((res) => {
          if (res) {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: "申请成功",
                type: "success"
              });
              this.$router.push({
                path:"/myTasksDetails",
                query:{
                  taskId: this.$route.query.taskId
                }
              })
            } else {
              this.canApply = true;
              this.$message({
                showClose: true,
                message: res.message,
                type: "warning",
              });
            }
          }
        });
    },
    timeLeftCount(data) {
      return new Date(data.replace(/-/g, "/")).getTime() - new Date().getTime();
    },
  },
};
</script>

<style lang="less" scoped>
.taskDetailPage {
  width: 1210px;
  margin: 20px auto;
  .left_section {
    float: left;
    width: 905px;
  }
}
.section {
  position: relative;
  padding: 30px 40px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;

  /deep/ .el-breadcrumb {
    height: 30px;
  }

  .sn {
    position: absolute;
    right: 40px;
    top: 27px;
  }

  header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    height: 70px;
    line-height: 70px;
    .title {
      font-size: 26px;
      color: #333;
      max-width: 60%;
    }
    .money {
      float: right;
      font-size: 32px;
      color: #f2641e;
      line-height: 56px;
      max-width: 40%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        margin-left: -10px;

        &.only {
          font-size: 22px;
          margin-left: 0;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    color: #666;
    line-height: 30px;
    height: 30px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    color: #999;
    line-height: 1.5;
  }

  .btn {
    position: absolute;
    right: 40px;
    bottom: 30px;
    .el-button {
      min-width: 120px;
      font-size: 18px;
    }
  }

  .content {
    font-size: 14px;
    color: #333;
    line-height: 24px;
    height: 500px;
    max-width: 100%;
    overflow-y: scroll;
    /deep/ img {
      max-width: 100%;
    }
  }
}
</style>